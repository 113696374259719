import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { Router } from '@angular/router';
import { ServicioService } from '../../servicios/servicio.service';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { find } from 'rxjs/operators';
import { ModalPagoComponent } from '../../componentes/modal-pago/modal-pago.component';
import { LoadingController, ModalController, PopoverController } from '@ionic/angular';
import { PopoverRetrasoComponent } from 'src/app/componentes/popover-retraso/popover-retraso.component';

declare var google;

@Component({
  selector: 'app-compra',
  templateUrl: './compra.component.html',
  styleUrls: ['./compra.component.scss']
})
export class CompraComponent implements OnInit {
  estadoProgramar: number = 0;
  datosTarjeta: any = {};
  pagoloading: any;
  mostrarAlerta: number = 0;
  tipoEntrega: any = 0;
  verPrecioTransporte: boolean = false;
  cliente: any;
  productoCarrito: any = [];
  vacio: boolean = false;
  total: number = 0;
  subTotal: number = 0;
  fechaProgamada: any = {};
  pedido: any = {};
  mensaje: string = '';
  loading: boolean = false;
  height: any;
  width: any;
  esMovil: boolean = true;
  totalCupon: number = 0;
  floatField: any;
  floatContainer: any;
  style: any = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ]

  fechaActual: any;
  horaActual: any;
  map: any;
  zoom: number = 15;
  lat: number = -17.783306;
  lng: number = -63.182127;
  @ViewChild('map', null) mapElement: ElementRef;
  markerOrigen: any;


  markers: marker[] = [];
  fecha = {}
  horario: any = [];
  rangoTransporte: any = {};
  telefono: any;
  estadoFechaProgramada: boolean = false;
  alertaFechas: number = 0;
  NuevoHorario: any[] = [];
  horariosTodo: any = [];
  metodosPago: any = [];
  constructor(
    private nav: Router,
    private ls: ServicioLocalService,
    private servicio: ServicioService,
    private location: Location,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private popoverController: PopoverController
  ) {
    this.fechaActual = moment().format("YYYY-MM-DD");
    this.horaActual = moment().format("HH:mm");
    // console.log('this.horaActual :>> ', this.horaActual);
    this.markers = [];
    this.markers.push({
      lat: this.lat,
      lng: this.lng,
      draggable: true,
      label: 'Dirección por defecto',
    });
    // console.log('this.markers[0].lat,this.markers[0].lng, this.markers[0].label :>> ', this.markers[0].lat, this.markers[0].lng, this.markers[0].label);
    this.agregarMarker1(this.markers[0].lat, this.markers[0].lng, this.markers[0].label)


    this.getPrecioMax();
    this.getMetodosPago();
    this.getScreenSize();
    //this.texto()
    this.ls.publishSomeDataNav(true);
    this.cliente = {};

    // existe carrito
    let carrito = JSON.parse(localStorage.getItem('ecommerce-carrito'));
    if (carrito) {
      this.productoCarrito = carrito;
      console.log('this.productoCarrito :>> ', this.productoCarrito);
      this.getHorarioAtencion(this.productoCarrito[0].idMarca)
      this.calcularTotal();
      // //console.log('this.productoCarrito :', this.productoCarrito);



    } else {
      // //console.log('no existe carrito.');
      this.vacio = true;
      this.nav.navigateByUrl('/inicio')
    }

    let dia = moment(new Date()).format("E");
    if (dia == '6' || dia == '7') {
      this.abrirPopoverRetraso();
    }



  }

  loadMap() {
    let latLng = new google.maps.LatLng(-17.783316, -63.182121);
    let mapOptions = {
      center: latLng,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: true,
      styles: this.ls.style,
      zoom: this.zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }
    //console.log('this.map :>> ', this.map);
    console.log('this.mapElement.nativeElement :>> ', this.mapElement);
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

    let punto = {};


    google.maps.event.addListener(this.map, 'click', (m) => {
      console.log(m.latLng.lat());
      console.log(m.latLng.lng());
      punto = {
        lat: m.latLng.lat(),
        lng: m.latLng.lng()
      }
      this.markers = [];
      this.markers.push({
        lat: m.latLng.lat(),
        lng: m.latLng.lng(),
        draggable: true,
        label: 'Tu Dirección',
      });
      console.log('this.markers :>> ', this.markers);
      this.agregarMarker1(m.latLng.lat(), m.latLng.lng(), 'Tu dirección')
    });



  }
  agregarMarker1(lat?, lng?, label?) {
    // this.markerOrigen.setMap(this.map);
    if (this.markerOrigen) {
      this.markerOrigen.setMap(null)
    }
    console.log('this.markerOrigen :>> ', this.markerOrigen);
    this.markerOrigen = new google.maps.Marker({
      // icon: new google.maps.MarkerImage('../../../assets/img/PETOdelivery.png', null, null, null, new google.maps.Size(30, 35)),
      map: this.map,
      draggable: false,
      animation: google.maps.Animation.DROP,
      position: { lat: lat, lng: lng }
    });
    let content = `<p>${label}</p>`;
    let infoWindow = new google.maps.InfoWindow({
      content: content
    });
    infoWindow.open(this.map, this.markerOrigen);

    google.maps.event.addListener(this.markerOrigen, 'click', () => {
      infoWindow.open(this.map, this.markerOrigen);
    });

    // this.zoom = 9;
    if (this.map) {
      this.markerOrigen.setPosition(new google.maps.LatLng(lat, lng));
      this.map.panTo(new google.maps.LatLng(lat, lng));
    }



  }

  async abrirPopoverRetraso() {
    let estilo: string;
    if (this.esMovil) {
      estilo = 'popCentro'
    } else {
      estilo = 'popCentroWeb'
    }
    console.log('estilo :>> ', estilo);
    const popover = await this.popoverController.create({
      component: PopoverRetrasoComponent,
      mode: 'md',
      cssClass: estilo,
      translucent: false
    });

    await popover.present();
  }


  //
  ngOnInit() {
    let cliente = JSON.parse(localStorage.getItem('cliente-e-commerce'));
    if (cliente) {
      this.cliente = cliente;
      this.pedido.razonSocial = this.cliente.razonSocial;
      this.pedido.nit = this.cliente.ci;
      this.pedido.direccion = this.cliente.direccion;
      this.pedido.telefono = this.cliente.telefono;
      this.cliente.ver = true;
      // //console.log('this.cliente :>> ', this.cliente);
      this.ls.publishSomeDataCliente(this.cliente);
      this.loadMap();

      navigator.geolocation.getCurrentPosition(pos => {
        this.lng = +pos.coords.longitude;
        this.lat = +pos.coords.latitude;
        this.markers = [];
        this.markers.push({
          lat: this.lat,
          lng: this.lng,
          draggable: true,
          label: 'Tu Dirección',
        });
        console.log('this.markers[0].lat,this.markers[0].lng, this.markers[0].label :>> ', this.markers[0].lat, this.markers[0].lng, this.markers[0].label);

        this.agregarMarker1(this.markers[0].lat, this.markers[0].lng, this.markers[0].label)
      },
        err => {
          //console.log('err :>> ', err);
          this.markers = [];
          this.markers.push({
            lat: this.lat,
            lng: this.lng,
            draggable: true,
            label: 'Dirección por defecto',
          });
          console.log('this.markers[0].lat,this.markers[0].lng, this.markers[0].label :>> ', this.markers[0].lat, this.markers[0].lng, this.markers[0].label);

          this.agregarMarker1(this.markers[0].lat, this.markers[0].lng, this.markers[0].label)

        });
    } else {
      // //console.log('no existe cliente');
      this.cliente.ver = false;
      this.ls.publishSomeDataCliente(this.cliente);
      localStorage.setItem('rutaAnterior-e-commerce', this.nav.url);
      this.nav.navigateByUrl('/login')
    }
    this.scrollTop();
  }
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
    if (this.width <= 600) {
      this.esMovil = true;
    } else {
      this.esMovil = false;

    }
    // // //console.log('height: '+this.height,'width: '+ this.width);
  }

  // horariosConFecha( horarios) {
  //   let dia = moment(new Date()).format("E");
  //   // let fechaActual = moment(new Date()).format("YYYY-MM-DD")

  //   if(dia == '1') {
  //     horarios = this.agregarFecha(moment(new Date()).format("YYYY-MM-DD"),dia, horarios);

  //   }
  //   return horarios
  // }
  // agregarFecha(fecha, dia, horarios) {
  //   for (let i = 0; i < horarios.length; i++) {
  //     if(horarios[i].idDia == dia) {
  //       horarios[i]['fecha'] = fecha;
  //     }
  //   }
  //   return horarios;
  // }
  getHorarioAtencion(idMarca) {
    ////console.log('idMarca :>> ', idMarca);
    this.servicio.getHorarioAtencion(idMarca).subscribe(
      res => {
        // this.spinnerImgen = false;
        console.log('res :', res);
        if (res['errorCode'] == 0) {
          let horarios: any = [];
          horarios = res['msg'];
          console.log('horarios ==========>>>>>:>> ', horarios);
          // horarios = this.horariosConFecha(horarios);
          this.horariosTodo = res['msg'];
          if (this.horariosTodo.length > 0) {
            let dia = moment(new Date()).format("E");
            //console.log('dia :>> ', dia);
            for (let i = 0; i < horarios.length; i++) {
              if (horarios[i].idDia == dia) {
                this.horario.push(horarios[i])
              }
            }
            //console.log('this.horario :>> ', this.horario);
            let horaActual = moment(new Date()).format('HH:mm:ss')
            let fechaActual = moment(new Date()).format("YYYY-MM-DD")
            if (this.horario.length > 0) {
              this.validarHorario(this.horario, horarios, horaActual, fechaActual);
            } else {
              // this.buscarNuevoHorario(dia,this.horariosTodo)
              this.validarHorario(this.horario, horarios, horaActual, fechaActual);
            }
            console.log('this.horario[0].idDia :>> ', horarios);
            let diaSiguiente = this.siguienteDia(this.horario[0].idDia);
            if( diaSiguiente < +dia) {

            }
            let dif = diaSiguiente - +dia
            console.log('dif :>> ', dif);
            console.log('diaSiguiente :>> ', diaSiguiente);
            let index = horarios.map(x => (+x.idDia) ).indexOf(diaSiguiente);
            console.log('index :>> ', index);
            console.log('horarios[index].horaApertura :>> ', horarios[index].horaApertura);
            for (let i = 0; i < this.productoCarrito.length; i++) {
              for (let j = 0; j < this.productoCarrito[i].productos.length; j++) {
                if (this.productoCarrito[i].productos[j].programar == 1) {
                  this.estadoFechaProgramada = true;
                  this.tipoEntrega = 1;
                  this.fechaProgamada.fechaProgamada = moment(new Date()).add(1,'day').format("YYYY-MM-DD")
                  this.fechaProgamada.horaProgramada = moment('2020-12-18 '+ horarios[index].horaApertura).add(60, 'minutes').format("HH:mm");
                  console.log('this.fechaProgamada.horaProgramada :>> ', this.fechaProgamada.horaProgramada);
                  this.estadoProgramar = 1;
                }
              }
            }

          }


        } else if (res['errorCode'] == 2) {
          // this.ls.showToast('warning', 'Alerta!', 'No hay datos' )

        } else if (res['errorCode'] == 3) {
          this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
        }
      },
      err => {
        this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    )
  }
  validarHorario(horario, horarios, horaActual, fechaActual) {
    console.log('horario :>> ', horario);
    console.log('horarios :>> ', horarios);
    console.log('horaActual :>> ', horaActual);
    this.alertaFechas = 0;
    let va = this.verificarAbierto(horarios, horaActual, fechaActual)
    if (va) {
      // //console.log('true compra inmediata activa');
      return true;
    } else {
      //      //console.log('false compra inmediata disabled');
      this.estadoFechaProgramada = true;
      this.tipoEntrega = 1;
      this.alertaFechas = 1;
      let dia = moment(new Date()).format("E");
      this.buscarNuevoHorario(dia, horarios);
      return false;
    }

  }
  buscarNuevoHorario(idDia, horarios) {
    this.NuevoHorario = [];
    for (let i = 0; i < horarios.length; i++) {
      if (horarios[i].idDia == idDia) {
        this.NuevoHorario.push(horarios[i]);
      }
    }
    let j = 0
    while (j < 3) {
      idDia = this.siguienteDia(idDia)
      for (let i = 0; i < horarios.length; i++) {

        if (horarios[i].idDia == idDia) {
          var index: number = this.NuevoHorario.indexOf(this.NuevoHorario.find(x => x.idDia == horarios[i].idDia && x.horaApertura == horarios[i].horaApertura));
          if (index >= 0) {
            j++;
          } else {
            this.NuevoHorario.push(horarios[i]);
            j++;
          }
        }
      }
    }
    // elimina duplicados





    //console.log('this.NuevoHorario :>> ', this.NuevoHorario);
  }
  siguienteDia(idDia) {
    if (idDia == 7) {
      idDia = 1;
    } else {
      idDia = (+idDia) + 1;
    }
    return idDia;
  }


  verificarAbierto(horario, hora, fecha) {
    // let horaActual = '19:00:10'
    let horaActual = moment().format("HH:mm")
    let fechaActual = moment().format("YYYY-MM-DD")
    let idDia = moment(fecha).format("E");
    console.log('idDia :>> ', idDia);
    for (let i = 0; i < horario.length; i++) {
      if (horario[i].idDia == idDia) {
        if (horario[i].horaApertura <= hora && hora <= horario[i].horaCierre) {
          return true
        }
      }
    }
    return false


  }
  //
  volverCarrito() {
    this.nav.navigateByUrl('/carrito')
  }

  texto() {
    this.floatField = document.getElementById('floatField');
    this.floatContainer = document.getElementById('floatContainer');
    this.floatField.addEventListener('focus', () => {
      this.floatContainer.classList.add('active');
    });
    this.floatField.addEventListener('blur', () => {
      this.floatContainer.classList.remove('active');
    });
  }


  getPrecioMax() {
    this.servicio.getPrecioTransporteMax().subscribe(
      res => {
        if (res['errorCode'] == 0) {
          this.rangoTransporte = res['msg'];
          if(this.rangoTransporte.delivery == 0){
            this.pedido.delivery = 0;
          }
        } else if (res['errorCode'] == 2) {

        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        //console.log('err :>> ', err);
      }
    );
  }

  getMetodosPago() {
    this.servicio.getListaMetodosPago().subscribe(
      res => {
        //  //console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.metodosPago = res['msg'];
          // //console.log(this.marcas);
        } else if (res['errorCode'] == 2) {

        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      });
  }

  selectDelivery(ev: any){

    if(ev.detail.value == 0 && this.pedido.metodoPago ==1){
      this.pedido.metodoPago =0;
    }
  }

  back() {
    this.location.back();
  }

  calcularTotal() {
    let total = 0;
    let sumaSubTotal = 0;

    for (let i = 0; i < this.productoCarrito.length; i++) {
      for (let j = 0; j < this.productoCarrito[i].productos.length; j++) {
        if (this.productoCarrito[i].productos[j].idCupon > 0) {
          let precioDescuento = 0;
          let totalProducto = 0;
          if (this.productoCarrito[i].productos[j].cupon.idTipoCupon == 1) {

            precioDescuento = this.getDescuentoPorcentaje(this.productoCarrito[i].productos[j].precio, this.productoCarrito[i].productos[j].cupon.total)
            totalProducto = this.productoCarrito[i].productos[j].cantidad * precioDescuento
            total = total + totalProducto;
            this.productoCarrito[i].productos[j].precioCupon = precioDescuento;



          } else if (this.productoCarrito[i].productos[j].cupon.idTipoCupon == 2) {
            precioDescuento = this.getDescuentoMonto(this.productoCarrito[i].productos[j].precio, this.productoCarrito[i].productos[j].cupon.total)
            totalProducto = this.productoCarrito[i].productos[j].cantidad * precioDescuento
            total = total + totalProducto;
            this.productoCarrito[i].productos[j].precioCupon = precioDescuento;


          }
        } else {
          let totalProducto = this.productoCarrito[i].productos[j].cantidad * this.productoCarrito[i].productos[j].precio;
          total = total + totalProducto;
        }
        // calculo subTotal
        let subTotal = this.productoCarrito[i].productos[j].cantidad * this.productoCarrito[i].productos[j].precio;
        sumaSubTotal = sumaSubTotal + subTotal;
      }
    }
    // //console.log('total :>> ', total);
    this.total = total;
    this.total = +(this.total.toFixed(2))

    this.subTotal = sumaSubTotal;
    this.subTotal = +(this.subTotal.toFixed(2))

    this.totalCupon = this.subTotal - this.total;
    this.totalCupon = +(this.totalCupon.toFixed(2))

    // this.totalPagar = this.total + this.transporte;
    // //console.log('this.productoCarrito :>> ', this.productoCarrito);
  }
  getDescuentoPorcentaje(precio: number, descuento: number): number {
    descuento = descuento / 100;
    descuento = precio * descuento
    let a = precio - descuento
    a = +(a.toFixed(2))

    // //console.log('a :>> ', a);
    return a;
  }
  getDescuentoMonto(precio: number, descuento: number): number {
    descuento = precio - descuento
    return descuento;
  }

  finalizarCompra() {
    // debugger
    this.registrarCompra(false)
  }
  compraWhatsapp() {
    // this.servicio.getSendWhatsapp('59169020896' , 'REgistrandocompra');
    this.registrarCompra(true)
  }
  async registrarCompra(what: boolean) {
    this.mostrarAlerta = 0;
    this.mensaje = undefined;
 
    this.loading = true;
    // console.log('this.fechaProgamada.fechaProgamada :>> ', this.fechaProgamada.fechaProgamada);
    //
    let buscar = " "
    let salto = '\n'
    let detalleWhatsapp = '';

    this.mostrarAlerta = 0;

    // this.fechaProgamada = {};
    delete this.pedido.fechaProg
    delete this.pedido.horaProg
    let detalle = [];
    //Asignando valor al objeto Pedido
    this.pedido.idtoken = this.cliente.idCliente;
    this.pedido.token = this.cliente.token;
    this.pedido.lat = this.markers[0].lat;
    this.pedido.lng = this.markers[0].lng;
    this.pedido.total = this.total;
    this.pedido.idCliente = this.cliente.idCliente;
    //---------------------


    let validoFecha: boolean;

    //calcular fecha programada tipoEntrega 1
    if (this.tipoEntrega == 1) {
      this.pedido.tipoEntrega = this.tipoEntrega;
      this.pedido.fechaProg = this.fechaProgamada.fechaProgamada
      this.pedido.horaProg = this.fechaProgamada.horaProgramada
      this.pedido.fechaEstimada = this.pedido.fechaProg;
      this.pedido.horaEstimada = this.pedido.horaProg;
      if (this.horariosTodo.length > 0 && this.horario.length > 0) {
        validoFecha = this.validarFechaProgramada(this.pedido.fechaProg, this.pedido.horaProg)
      } else {
        validoFecha = true;
      }

    } else {
      //calcular fecha estimada tipoentrega 0
      let fechaActual = moment(new Date()).format("YYYY-MM-DD");
      let horaActual = moment(new Date()).format("HH:mm:ss");
      var horaEstimada = moment(new Date()).add(45, 'minutes').format('HH:mm:ss');
      this.pedido.fechaEstimada = fechaActual;
      this.pedido.horaEstimada = horaEstimada;
      this.pedido.tipoEntrega = this.tipoEntrega;
      //console.log('this.horariosTodo :>> ', this.horariosTodo);
      //console.log('this.horario.length :>> ', this.horario.length);
      if (this.horariosTodo.length > 0 && this.horario.length > 0) {
        validoFecha = this.validarHorario(this.horario, this.horariosTodo, horaActual, fechaActual)
      } else {
        validoFecha = true;
      }
      //---------------------
    }
    //------------------------
    //------------------------------
    if (this.pedido.nombreEnvio != undefined || this.pedido.nombreEnvio != '') {
      detalleWhatsapp = detalleWhatsapp + `\n\nEnvío para : *${this.pedido.nombreEnvio}*\n\n`
    }
    // cargando valores al array de objetos "detalle"
    for (let i = 0; i < this.productoCarrito.length; i++) {
      // texto de whatsapp
      detalleWhatsapp = detalleWhatsapp + `\n\nMarca: *${this.productoCarrito[i].NombreMarcar}*\n\n`
      for (let j = 0; j < this.productoCarrito[i].productos.length; j++) {
        let objDetalle = {}
        objDetalle['idProducto'] = this.productoCarrito[i].productos[j].idProducto;
        objDetalle['cantidad'] = this.productoCarrito[i].productos[j].cantidad;
        //calculo de precio del producto.
        if (this.productoCarrito[i].productos[j].idCupon > 0) {
          objDetalle['precio'] = this.productoCarrito[i].productos[j].precioCupon;
        } else {
          objDetalle['precio'] = this.productoCarrito[i].productos[j].precio;
        }
        objDetalle['idCupon'] = this.productoCarrito[i].productos[j].idCupon;
        objDetalle['limite'] = this.productoCarrito[i].productos[j].limite;
        // texto de whatsapp
        detalleWhatsapp = detalleWhatsapp + `Producto: *${this.productoCarrito[i].productos[j].nombre}*\nCantidad: *${this.productoCarrito[i].productos[j].cantidad}*\nPrecio: ${objDetalle['precio']}\n`

        detalle.push(objDetalle);
      }
    }
    detalleWhatsapp = detalleWhatsapp + `\nTotal: *${this.pedido.total}*\n`;

    this.pedido.detalle = detalle
    //  -------------------------
    // //console.log('this.pedido :>> ', this.pedido);

    // Funcion de validacion.
    let valido = this.validarCompra(this.pedido);
    if (valido == true) {
      if (validoFecha == true) {
        //console.log('this.pedido :>> ', this.pedido);

        if (this.pedido.metodoPago == 2) { // pago con trjeta

          const modal = await this.modalController.create({
            component: ModalPagoComponent,
            componentProps: { tarjeta: this.datosTarjeta }
          });

          await modal.present();
          const { data } = await modal.onDidDismiss();
          this.loading = false;
          if (data.res == true) {
            let t = this.convertirTotal(this.pedido.total);
            //console.log('res.datos :>> ', data.datos);
            this.datosTarjeta = data.datos;
            //console.log('this.datosTarjeta :>> ', this.datosTarjeta);
            //console.log('Realizar la compra con tarjeta');
            //console.log('t :>> ', t);
            // this.pedido['tarjeta'] = data.datos;
            this.pedido['idFormaPago'] = 2;
            this.pedido['monto'] = t;
            this.pedido['cvv2'] = data.datos.cvv2;
            this.pedido['fecha_envio'] = data.datos.fecha_envio;
            this.pedido['fecha_expiracion'] = data.datos.fecha_expiracion;
            this.pedido['hora_envio'] = data.datos.hora_envio;
            this.pedido['nombre_cliente'] = data.datos.nombre_cliente;
            this.pedido['tarjeta'] = data.datos.tarjeta;
            // console.log('this.pedido :>> ', this.pedido);
            ////////////////////
            this.mostrarLoading('Procesando pago');
            this.servicio.postInsertarPedido(this.pedido).subscribe(
              res => {
                this.cerrarLoading();
                this.loading = false;

                // console.log('res :', res);
                if (res['errorCode'] == 0) {
                  this.actualizarTelefono(this.pedido.telefono);

                  //Compra realizada con exito
                  if (what == false) {
                    this.ls.publishSomeDataCarrito(0);
                    this.nav.navigateByUrl('/estado-compra/' + res['idPedido']);
                    localStorage.removeItem('ecommerce-carrito');
                    this.ls.showToast('success', 'Correcto!', 'Pedido realizado con exito')
                  } else {
                    this.ls.publishSomeDataCarrito(0);
                    this.nav.navigateByUrl('/estado-compra/' + res['idPedido']);
                    localStorage.removeItem('ecommerce-carrito');
                    this.ls.showToast('success', 'Correcto!', 'Pedido realizado con exito, En breve se abrira tu Whatsapp.');
                    setTimeout(() => {
                      //whatsapp
                      detalleWhatsapp = `*Pedido Realizado Con Éxito.*\nCODIGO PEDIDO: *${res['idPedido']}*\n\nDireccion Cliente: *${this.pedido.direccion}*\n\n*DETALLE DEL PEDIDO*\n` + detalleWhatsapp;
                      let and = '&'
                      detalleWhatsapp = detalleWhatsapp.replace(new RegExp(salto, "g"), "%0D%0A");
                      detalleWhatsapp = detalleWhatsapp.replace(new RegExp(buscar, "g"), "%20");
                      detalleWhatsapp = detalleWhatsapp.replace(new RegExp(and, "g"), "-");
                      let ruta = 'https://api.whatsapp.com/send?phone=+591' + this.rangoTransporte.telefono + '&text=' + detalleWhatsapp;
                      // //console.log('ruta :>> ', ruta);
                      window.open(ruta);

                    }, 0);
                  }
                } else if (res['errorCode'] == 2) {
                  // Alerta no hay datos.
                  // this.ls.showToast('warning', 'Alerta!', 'no fue posible registrar pedido')
                  this.mostrarAlerta = 2;
                } else if (res['errorCode'] == 3) {
                  //Alerta error en el servicio
                  // this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
                  this.mostrarAlerta = 3;

                } else if (res['errorCode'] == 4) {
                  //Alerta usuario no autenticado
                  // this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
                  this.mostrarAlerta = 4;

                } else if (res['errorCode'] == 5) {
                  //Alerta El producto no tiene stock suficiente
                  // this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
                  this.mostrarAlerta = 5;

                } else if (res['errorCode'] == 6) {
                  //Alerta El producto no está disponible, intenté programar una fecha y hora o vuelve a intentar más tarde
                  // this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
                  this.mostrarAlerta = 6;

                } else if (res['errorCode'] == 7) {
                  this.mostrarAlerta = 7;
                  //Alerta El cupon ya no está disponible
                  // this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
                } else if (res['errorCode'] == 10) {
                  this.mostrarAlerta = 10;

                } else if (res['errorCode'] == 11) {
                  this.mostrarAlerta = 11;
                } else if (res['errorCode'] == 12) {
                  this.mostrarAlerta = 12;
                } else {
                  this.mostrarAlerta = 13;
                }
                // } else if (res['errorCode'] == 14) {
                //   this.mostrarAlerta = 14;
                // } else if (res['errorCode'] == 15) {
                //   this.mostrarAlerta = 15;
                // } else if (res['errorCode'] == 16) {
                //   this.mostrarAlerta = 16;
                // } else if (res['errorCode'] == 17) {
                //   this.mostrarAlerta = 17;
                // } else if (res['errorCode'] == 18) {
                //   this.mostrarAlerta = 18;
                // } else if (res['errorCode'] == 19) {
                //   this.mostrarAlerta = 19;
                // } else if (res['errorCode'] == 20) {
                //   this.mostrarAlerta = 20;
                // } else if (res['errorCode'] == 21) {
                //   this.mostrarAlerta = 21;
                // } else if (res['errorCode'] == 22) {
                //   this.mostrarAlerta = 22;
                // } else if (res['errorCode'] == 23) {
                //   this.mostrarAlerta = 23;
                // } else if (res['errorCode'] == 24) {
                //   this.mostrarAlerta = 24;
                // }
              },
              err => {
                this.cerrarLoading();
                this.loading = false;
                this.mostrarAlerta = 3;

                // console.log('err :', err);
                // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
              }
            );

            ///////////////////////

          } else {
            //console.log('object :>>  no autorizo la compra.');
          }

        } else if (this.pedido.metodoPago == 1 || this.pedido.metodoPago == 3 || this.pedido.metodoPago == 4) {
          this.pedido['idFormaPago'] = this.pedido.metodoPago;
          // console.log('this.pedido :>> ', this.pedido);
          this.servicio.postInsertarPedido(this.pedido).subscribe(
            res => {
              this.loading = false;

              this.actualizarTelefono(this.pedido.telefono);

              // console.log('res :', res);
              if (res['errorCode'] == 0) {
                //Compra realizada con exito
                if (what == false) {
                  this.ls.publishSomeDataCarrito(0);
                  this.nav.navigateByUrl('/estado-compra/' + res['idPedido']);
                  localStorage.removeItem('ecommerce-carrito');
                  this.ls.showToast('success', 'Correcto!', 'Pedido realizado con exito')
                } else {
                  this.ls.publishSomeDataCarrito(0);
                  this.nav.navigateByUrl('/estado-compra/' + res['idPedido']);
                  localStorage.removeItem('ecommerce-carrito');
                  this.ls.showToast('success', 'Correcto!', 'Pedido realizado con exito, En breve se abrira tu Whatsapp.');
                  setTimeout(() => {
                    //whatsapp
                    detalleWhatsapp = `*Pedido Realizado Con Éxito.*\nCODIGO PEDIDO: *${res['idPedido']}*\n\nDireccion Cliente: *${this.pedido.direccion}*\n\n*DETALLE DEL PEDIDO*\n` + detalleWhatsapp;
                    let and = '&'
                    detalleWhatsapp = detalleWhatsapp.replace(new RegExp(salto, "g"), "%0D%0A");
                    detalleWhatsapp = detalleWhatsapp.replace(new RegExp(buscar, "g"), "%20");
                    detalleWhatsapp = detalleWhatsapp.replace(new RegExp(and, "g"), "-");
                    let ruta = 'https://api.whatsapp.com/send?phone=+591' + this.rangoTransporte.telefono + '&text=' + detalleWhatsapp;
                    // //console.log('ruta :>> ', ruta);
                    // this.servicio.getSendWhatsapp('59169020896' , detalleWhatsapp);
                    window.open(ruta);
                  }, 0);
                }
              } else if (res['errorCode'] == 2) {
                // Alerta no hay datos.
                // this.ls.showToast('warning', 'Alerta!', 'no fue posible registrar pedido')
                this.mostrarAlerta = 2;
              } else if (res['errorCode'] == 3) {
                //Alerta error en el servicio
                // this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
                this.mostrarAlerta = 3;

              } else if (res['errorCode'] == 4) {
                //Alerta usuario no autenticado
                // this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
                this.mostrarAlerta = 4;

              } else if (res['errorCode'] == 5) {
                //Alerta El producto no tiene stock suficiente
                // this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
                this.mostrarAlerta = 5;

              } else if (res['errorCode'] == 6) {
                //Alerta El producto no está disponible, intenté programar una fecha y hora o vuelve a intentar más tarde
                // this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
                this.mostrarAlerta = 6;

              } else if (res['errorCode'] == 7) {
                this.mostrarAlerta = 7;
                //Alerta El cupon ya no está disponible
                // this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
              }
            },
            err => {
              this.loading = false;
              this.mostrarAlerta = 3;

              //console.log('err :', err);
              // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
            }
          );
        }

      } else {
        // false validar FECHA
        setTimeout(() => {
          this.loading = false;

          this.mostrarAlerta = 8;
          this.mensaje = 'Fecha no valida, Sucursal cerrada'

        }, 1000);
      }


    } else {
      // //console.log('no valido');

      this.loading = false;
      this.mensaje = valido;
    }
    //------------------------------------
  }
  actualizarTelefono(telefono) {
    let cliente = JSON.parse(localStorage.getItem('cliente-e-commerce'));
    cliente.telefono = telefono;
    cliente.idtoken = cliente.idCliente;
    this.servicio.postModificarCliente(cliente).subscribe(
      res => {
        // console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.ls.showToast('success', 'Correcto!', 'Tu número de teléfono fue actualizado correctamente')
          localStorage.setItem('cliente-e-commerce', JSON.stringify(cliente));
        } else if (res['errorCode'] == 2) {
          // this.ls.showToast('warning', 'Alerta!', 'No hay datos' )

        } else if (res['errorCode'] == 3) {
          // this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio' )
        }
      },
      err => {

        // console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );

  }


  async mostrarLoading(texto) {
    this.pagoloading = await this.loadingController.create({
      message: texto,
      spinner: 'bubbles'
    });
    await this.pagoloading.present();

  }
  cerrarLoading() {
    this.pagoloading.dismiss()
  }
  convertirTotal(num) {
    //aquí elimino todo lo que no sea números o comas (,)
    let r = '';
    num = num.toFixed(2);
    let decimal: any;
    //console.log('inicio num :>> ', num);
    //convierto a string
    num = num.toString();
    //console.log('num :>> ', num);
    let i = num.indexOf('.')
    if (i > 0) {
      decimal = num.substr(i + 1, 2)
      r = r + decimal;
      i = i - 1
      for (let index = i; index >= 0; index--) {
        r = num[index] + r;
      }
      while (r.length < 12) {
        r = '0' + r
      }
      //console.log('fin r :>> ', r, '--- ', r.length);
      return r;
    } else {
      r = num;
      r = num + '00'
      while (r.length < 12) {
        r = '0' + r
      }
      //console.log('fin r :>> ', r, '--- ', r.length);
      return r;

    }

  }

  validarFechaProgramada(fechaProg, horaProg) {
    if (fechaProg != undefined && fechaProg != '' && fechaProg != 'Invalid date' && horaProg != undefined && horaProg != '' && horaProg != 'Invalid date') {
      let horario = [];
      let dia = moment(fechaProg).format("E");
      for (let i = 0; i < this.horariosTodo.length; i++) {
        if (this.horariosTodo[i].idDia == dia) {
          horario.push(this.horariosTodo[i])
          //   //console.log('this.horariosTodo[i]:>> ', this.horariosTodo[i]);
        }
      }
      //console.log(this.validarHorario(horario, this.horariosTodo, horaProg))

      return this.validarHorario(horario, this.horariosTodo, horaProg, fechaProg);
    } else {
      return false;
    }

  }

  verPrecioT() {
    if (this.verPrecioTransporte == false) {
      this.verPrecioTransporte = true;
    } else {
      this.verPrecioTransporte = false;
    }
  }


  // ========>>>>>> GOOGLE MAPS
  clickedMarker(label: string, index: number) {
    // //console.log(`clicked the marker: ${label || index}`);
  }

  obtenerUbicacion() {
    navigator.geolocation.getCurrentPosition(res => {
      // console.log('res :>> ', res);
      this.lat = res.coords.latitude;
      this.lng = res.coords.longitude;
      this.zoom = 15;
      this.markers = [];
      this.markers.push({
        lat: this.lat,
        lng: this.lng,
        draggable: true,
        label: 'Dirección',
      });
      // console.log('this.markers[0].lat,this.markers[0].lng, this.markers[0].label :>> ', this.markers[0].lat, this.markers[0].lng, this.markers[0].label);

      this.agregarMarker1(this.markers[0].lat, this.markers[0].lng, this.markers[0].label)

    },
      err => {
        // console.log('err :>> ', err);
        if (err.code == 1) {
          this.ls.showToast('warning', 'Alerta!', 'Debes aceptar el permiso de acceso a ubicación')
        }

      })
  }

  // mapClicked($event: any) {
  //   this.cliente.latitud = $event.coords.lat;
  //   this.cliente.longitud = $event.coords.lng;
  //   this.markers = [];
  //   this.markers.push({
  //     lat: $event.coords.lat,
  //     lng: $event.coords.lng,
  //     draggable: true,
  //     label: 'Tu Dirección',
  //   });
  //   // //console.log('this.markers :>> ', this.markers);
  // }

  validarCompra(pedido) {
    if (pedido.razonSocial == undefined || pedido.razonSocial == '') {
      this.mostrarAlerta = 8;
      return 'Campo requerido Razón Social';
    } else if (pedido.nit == undefined) {
      this.mostrarAlerta = 8;
      return 'Campo requerido Nit';
    } else if (pedido.telefono == undefined || pedido.telefono == '') {
      this.mostrarAlerta = 8;
      return 'Campo requerido Teléfono';
    } else if (pedido.direccion == undefined || pedido.direccion == '') {
      this.mostrarAlerta = 8;
      return 'Campo requerido Dirección';
    } else if (pedido.lat == undefined) {
      this.mostrarAlerta = 8;
      return 'Campo requerido Ubicación';
    } else if (pedido.lng == undefined) {
      this.mostrarAlerta = 8;
      return 'Campo requerido Ubicación';
    } else if (pedido.metodoPago == undefined) {
      this.mostrarAlerta = 8;
      return 'Campo requerido Método de pago';
    } else if (pedido.delivery == undefined) {
      this.mostrarAlerta = 8;
      return 'Campo requerido, Selecciona información de despacho';
    } else if (pedido.lat == -17.783306) {
      this.mostrarAlerta = 8;
      return 'Alerta! Selecciona tu ubicación en el mapa para continuar por favor';
    }
    return true;
  }
  navegarLogin() {
    localStorage.setItem('rutaAnterior-e-commerce', this.nav.url);
    this.nav.navigateByUrl('/login')
  }

  async abrirPago() {

  }

}


interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;

}
